<template>
  <div>
    <title-nav :title="'메인 강좌'" :nav="'CS / 메인 강좌'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col>
              <b-input-group size="sm" prepend="강좌 번호" class="mb-1">
                <b-form-input size="sm" v-model="lc_no" class="" placeholder="필수"></b-form-input>
                <b-input-group-append>
                  <b-button @click.prevent="$refs['modal-lc'].show()">강좌 검색</b-button>
                </b-input-group-append>
              </b-input-group> </b-col
            ><b-col>
              <b-form-radio-group v-model="main_type" name="radio-sub-component">
                <b-form-radio value="HOT">HOT</b-form-radio>
                <b-form-radio value="FREE">무료</b-form-radio>
              </b-form-radio-group></b-col
            >
          </b-row>
          <div v-if="lc_no">
            <b-card bg-variant="light">
              <b-form-group
                label="카테고리:"
                label-for="nested-street"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="sm"
                class="mb-1"
              >
                <b-form-input
                  id="nested-street"
                  size="sm"
                  readonly
                  :value="`${cate1} / ${cate2} / ${cate3}`"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="강좌명:"
                label-for="nested-city"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="sm"
                class="mb-1"
              >
                <b-form-input id="nested-city" size="sm" readonly :value="title"></b-form-input>
              </b-form-group>

              <b-form-group
                label="강사명:"
                label-for="nested-state"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="sm"
                class="mb-1"
              >
                <b-form-input
                  id="nested-state"
                  size="sm"
                  readonly
                  :value="lecturer_name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Tag:"
                label-for="nested-country"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="sm"
              >
                <b-form-input id="nested-country" size="sm" readonly :value="tag"></b-form-input>
              </b-form-group>
            </b-card>
          </div>
          <b-row class="mb-1">
            <b-col
              ><b-input-group size="sm" prepend="BOX 제목" class="">
                <b-form-input
                  size="sm"
                  v-model="category"
                  placeholder="필수"
                ></b-form-input> </b-input-group
            ></b-col>
            <b-col
              ><b-input-group size="sm" prepend="Tag1" class="">
                <b-form-input
                  size="sm"
                  v-model="tag1"
                  placeholder="필수"
                ></b-form-input> </b-input-group
            ></b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              ><b-input-group size="sm" prepend="Tag2" class="">
                <b-form-input size="sm" v-model="tag2"></b-form-input> </b-input-group
            ></b-col>
            <b-col
              ><b-input-group size="sm" prepend="Tag3" class="">
                <b-form-input size="sm" v-model="tag3"></b-form-input> </b-input-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col
              ><b-input-group size="sm" prepend="Tag4" class="">
                <b-form-input size="sm" v-model="tag4"></b-form-input> </b-input-group
            ></b-col>
            <b-col
              ><b-input-group size="sm" prepend="Tag5" class="">
                <b-form-input size="sm" v-model="tag5"></b-form-input> </b-input-group
            ></b-col>
          </b-row>
          <b-button @click="cancel" class="m-1">
            취소
          </b-button>
          <b-button @click="updateMain" class="m-1" variant="primary">
            확인
          </b-button>
          <b-button @click="deleteMain" variant="danger" v-if="isEditMode">삭제 </b-button>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
            style="font-size:.8rem"
          >
            <template v-slot:cell(reg_dt)="data">
              {{ data.value | dateFilter }}
            </template>
            <template v-slot:cell(lc_no)="data">
              {{ data.value }}
            </template>
            <template v-slot:cell(custom_edit)="data">
              <b-button variant="link" @click="fillData(data.item)">수정/내용보기 </b-button>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
    <!-- 삭제 모달창 -->
    <b-modal ref="modal-delete" hide-footer title="정말 삭제하시겠습니까?">
      <b-button class="mt-3" variant="outline-danger" block @click="requestDelete">삭제</b-button>
      <b-button class="mt-2" variant="outline-warning" block @click="closeModal">아니요</b-button>
    </b-modal>
    <!-- 강좌 검색 -->
    <b-modal size="xl" scrollable ref="modal-lc" hide-footer title="강좌 선택">
      <lecture-select
        :isSearch="true"
        @updateOpt1="updateOpt1"
        @updateOpt2="updateOpt2"
        @updateOpt3="updateOpt3"
        @updateLcNo="updateLcNo"
      />
      <b-table
        striped
        hover
        bordered
        class="table-sm text-sm-center table-head-fixed"
        :borderless="borderless"
        :dark="dark"
        :fixed="fixed"
        :no-border-collapse="noCollapse"
        :items="lc_items"
        :fields="lc_fields"
        :head-variant="headVariant"
        :busy="isBusy"
        show-empty
        style="font-size:.8rem"
      >
        <template v-slot:cell(reg_dt)="data">
          <span>{{ data.value | dateFilter }}</span>
        </template>
        <template v-slot:cell(lc_no)="data">
          <b-button variant="link" @click="selectLC(data)">{{ data.value }}</b-button>
        </template>
        <template v-slot:cell(is_close)="data">
          <span v-if="data.value == 'Y'"
            ><b-icon icon="toggle2-on" scale="1.5" variant="success"></b-icon
          ></span>
          <span v-else><b-icon icon="toggle2-off" scale="1.5" variant="secondary"></b-icon></span>
        </template>
        <template v-slot:cell(is_cost)="data">
          <span v-if="data.value == 'Y'"
            ><b-icon icon="toggle2-on" scale="1.5" variant="info"></b-icon
          ></span>
          <span v-else><b-icon icon="toggle2-off" scale="1.5" variant="secondary"></b-icon></span>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { getMainList, createMain, deleteMain } from '@/api/cs'
import { getLectureList, getLectureInfo } from '../../api/lecture'
import LectureSelect from '../util/LectureSelect'
export default {
  name: 'MainLecture',
  components: { LectureSelect },
  data() {
    return {
      page: 1,
      rowPerPage: 10,
      records: 0,
      isBusy: false,
      isEditMode: false,
      lc_no: '',
      main_type: 'HOT',
      category: '',
      tag1: '',
      tag2: '',
      tag3: '',
      tag4: '',
      tag5: '',
      lecturer_name: '',
      title: '',
      tag: '',
      cate1: '',
      cate2: '',
      cate3: '',
      fields: [
        {
          key: 'main_type',
          label: '구분',
          colType: 'span',
          variant: 'info'
        },
        {
          key: 'category',
          label: 'Box 제목',
          colType: 'span',
          variant: 'primary'
        },
        {
          key: 'cate1',
          label: '대',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'cate2',
          label: '중',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'cate3',
          label: '소',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'lc_no',
          label: '강좌 No.',
          colType: 'span',
          variant: 'danger'
        },
        {
          key: 'title',
          label: '강좌제목',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'reg_dt',
          label: '등록일시',
          colType: 'span'
        },
        {
          key: 'custom_edit',
          label: '수정/내용보기',
          colType: 'span'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      opt1: '',
      opt2: '',
      opt3: '',
      opt4: '',
      lc_items: [],
      lc_fields: [
        {
          key: 'cate1',
          label: '대',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'cate2',
          label: '중',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'cate3',
          label: '소',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'play_order',
          label: 'PlayNo.',
          colType: 'span'
        },
        {
          key: 'lc_no',
          label: '강좌 No.',
          colType: 'span'
        },
        {
          key: 'title',
          label: '강좌제목',
          colType: 'span',
          tdClass: 'text-left'
        },
        {
          key: 'is_close',
          label: '숨김여부',
          colType: 'span'
        },

        {
          key: 'is_cost',
          label: '유료여부',
          colType: 'span'
        }
      ]
    }
  },
  methods: {
    async requestDelete() {
      const response = await deleteMain(this.lc_no)
      if (response.code === '0000') {
        this.getList()
        alert('삭제 되었습니다.')
      } else {
        alert(response.message)
      }
      this.$refs['modal-delete'].hide()
    },
    closeModal() {
      this.$refs['modal-delete'].hide()
    },
    fillData(item, mode = true) {
      this.lc_no = item.lc_no
      this.main_type = item.main_type
      this.category = item.category
      this.title = item.title
      this.cate1 = item.cate1
      this.cate2 = item.cate2
      this.cate3 = item.cate3
      this.tag1 = item.tag1
      this.tag2 = item.tag2
      this.tag3 = item.tag3
      this.tag4 = item.tag4
      this.tag5 = item.tag5
      this.isEditMode = mode

      getLectureInfo(item.lc_no).then(resp => {
        this.tag = resp.data.tag
        this.lecturer_name = resp.data.lecturer_name
      })
    },
    deleteMain() {
      this.$refs['modal-delete'].show()
    },
    async updateMain() {
      if (this.lc_no && this.category) {
        const response = await createMain(
          this.lc_no,
          this.main_type,
          this.category,
          this.tag1,
          this.tag2,
          this.tag3,
          this.tag4,
          this.tag5
        )
        if (response.code === '0000') {
          alert('반영되었습니다.')
          this.cancel()
          this.getList()
        } else {
          alert('반영 실패하였습니다. ' + response.message)
        }
      } else {
        alert('데이터를 모두 입력하세요')
      }
    },
    cancel() {
      this.isEditMode = false
      this.lc_no = null
      this.main_type = null
      this.category = null
      this.tag1 = this.tag2 = this.tag3 = this.tag4 = this.tag5 = null
    },
    async getList() {
      this.isBusy = true
      const response = await getMainList(this.page, this.rowPerPage)

      this.items = response.data.list
      this.records = response.data.totCnt
      this.isBusy = false
    },
    setPage(page) {
      this.page = page
      this.getList()
    },
    setCondition() {
      this.page = 1
      this.getList()
    },
    selectLC(data) {
      this.fillData(data.item, false)
      this.$refs['modal-lc'].hide()
    },
    updateOpt1(data) {
      this.opt1 = data.id
      var params = `cate1=${this.opt1}`
      getLectureList(params).then(_resp => {
        this.lc_items = _resp.data.list
      })
    },
    updateOpt2(data) {
      if (data == '') return
      this.opt2 = data.id
      var params = `cate1=${this.opt1}&cate2=${this.opt2}`
      getLectureList(params).then(_resp => {
        this.lc_items = _resp.data.list
      })
    },
    updateOpt3(data) {
      if (data.id < 0) return
      this.opt3 = data.id
      var params = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}`
      getLectureList(params).then(_resp => {
        this.lc_items = _resp.data.list
      })
    },
    updateLcNo(data) {
      this.opt4 = data
      var params = `cate1=${this.opt1}&cate2=${this.opt2}&cate3=${this.opt3}&searchText=${this.opt4}`
      getLectureList(params).then(_resp => {
        this.lc_items = _resp.data.list
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  padding-left: 10px;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: left;
  padding-left: 10px;
}
</style>
